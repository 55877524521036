import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import cpeService from './cpeService';

export default {
  getCpesRating(ctx) {
    ctx.$store.commit('toggleLoadingCpesRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();

    const isWlansFilter = ctx.filters.wlan !== 'All wlans';

    const query = {
      action: 'R',
      timebounds: { start, stop },
      ids: isWlansFilter ? [ctx.filters.wlan] : []
    };

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/rating/${isWlansFilter ? 'wlans/' : ''}cpes`, query)
      .then((response) => {
        if (response.data.status === 'success') {
          const cpesRatingList = response.data.data.itemslist;
          ctx.$store.commit('setCpesRatingList', cpesRatingList);
          const cpesIds = new Set();
          response.data.data.itemslist.forEach((cpe) => {
            cpesIds.add(cpe.id);
          });
          cpeService.getSelectedCpes(ctx, [...cpesIds]);
          ctx.$store.commit('toggleLoadingCpesRating');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleLoadingCpesRating');
          VueNotifications.error({ message: response.data.description });
        }
      });
  },
  getCpesRatingWithLocationFilter(ctx) {
    ctx.$store.commit('toggleLoadingCpesRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();

    const isWlansFilter = ctx.filters.wlan !== 'All wlans';
    let locationForQ;
    let withChilds = ctx.filters.with_childs;
    if (ctx.filters.location === 'All locations') {
      locationForQ = ctx.userBaseLocation;
      withChilds = true;
      ctx.filters.with_childs = true;
    } else {
      locationForQ = ctx.filters.location;
    }
    // if (ctx.filters.company !== 'All companys') {
    //   ctx.filters.location = 'All locations';
    //   ctx.filters.with_childs = false;
    //   withChilds = false;
    //   locationForQ = ctx.filters.company.locationId;
    //   // withChilds = false;
    // }

    const query = {
      action: 'R',
      timebounds: { start, stop },
      ids: isWlansFilter ? [ctx.filters.wlan] : [],
      location: locationForQ,
      with_childs: withChilds
    };

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/rating/${isWlansFilter ? 'wlans/' : ''}cpes`, query)
      .then((response) => {
        if (response.data.status === 'success') {
          const cpesRatingList = response.data.data.itemslist;
          ctx.$store.commit('setCpesRatingList', cpesRatingList);
          const cpesIds = new Set();
          response.data.data.itemslist.forEach((cpe) => {
            cpesIds.add(cpe.id);
          });
          cpeService.getSelectedCpes(ctx, [...cpesIds]);
          ctx.$store.commit('toggleLoadingCpesRating');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleLoadingCpesRating');
          VueNotifications.error({ message: response.data.description });
        }
      });
  },
  getCpesRatingWithLocationFilterWithPagination(ctx) {
    ctx.$store.commit('toggleLoadingCpesRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();

    const isWlansFilter = ctx.filters.wlan !== 'All wlans';
    let locationForQ;
    let withChilds = ctx.filters.with_childs;
    if (ctx.filters.location === 'All locations') {
      locationForQ = ctx.userBaseLocation;
      withChilds = true;
      ctx.filters.with_childs = true;
    } else {
      locationForQ = ctx.filters.location;
    }
    // if (isWlansFilter) {
    //   locationForQ = ctx.userBaseLocation;
    //   withChilds = true;
    // }
    // if (ctx.filters.company !== 'All companys') {
    //   ctx.filters.location = 'All locations';
    //   ctx.filters.with_childs = false;
    //   withChilds = false;
    //   locationForQ = ctx.filters.company.locationId;
    //   // withChilds = false;
    // }

    let ids = []
    if (isWlansFilter) {
      ids = [ctx.filters.wlan]
    } else if (ctx.filters.cpeId) {
      ids = [ctx.filters.cpeId]
    }

    const query = {
      action: 'R',
      timebounds: {start, stop},
      // ids: isWlansFilter ? [ctx.filters.wlan] : [],
      ids: ids,
      location: locationForQ,
      with_childs: withChilds,
      page: ctx.filters.page
    };

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/rating/${isWlansFilter ? 'wlans/' : ''}cpes`, query)
      .then((response) => {
        if (response.data.status === 'success') {
          const cpesRatingList = response.data.data.itemslist;
          ctx.$store.commit('pushCpesRatingList', cpesRatingList);
          const totalResult = response.data.data.total;
          ctx.totalResult = totalResult;
          // console.log(ctx.$store.state.cpesRatingList.length)
          if (ctx.$store.state.cpesRatingList.length >= totalResult) {
            // console.log('case canLoadMore = false ')
            ctx.canLoadMore = false;
          } else {
            // console.log('case canLoadMore = true ')
            ctx.canLoadMore = true;
          }
          const cpesIds = new Set();
          if (ctx.$store.state.cpesRatingList.length > 0) {
            ctx.$store.state.cpesRatingList.forEach((cpe) => {
              cpesIds.add(cpe.id);
            });
            cpeService.getSelectedCpes(ctx, [...cpesIds]);
          }
          ctx.$store.commit('toggleLoadingCpesRating');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleLoadingCpesRating');
          VueNotifications.error({message: response.data.description});
        }
      }, (err) => {
        // console.log(err)
        VueNotifications.error({message: err});
        ctx.$store.commit('toggleLoadingCpesRating');
      });
  },

  // getCpesSessions(ctx) {
  //   ctx.$store.commit('toggleLoadingCpesSessions');
  //   const start = moment(ctx.drPickerDateForRating.startDate).unix();
  //   const stop = moment(ctx.drPickerDateForRating.endDate).unix();
  //   Vue.axios
  //     .post(`${window.RESTAPI || ''}/api/stat/session/cpes`, {
  //       action: 'R',
  //       timebounds: { start, stop }
  //     })
  //     .then(
  //       (response) => {
  //         if (response.data.status === 'success') {
  //           ctx.$store.commit('setCpesSessionsList', response.data.data.itemslist);
  //           if (ctx.statCpeId) {
  //             ctx.responseStats(this);
  //           }
  //         } else if (response.data.status === 'error') {
  //           VueNotifications.error({ message: response.data.description });
  //         }
  //         ctx.$store.commit('toggleLoadingCpesSessions');
  //       },
  //       (err) => {
  //         // VueNotifications.error({ message: err });
  //
  //         // show errors in the console instead of the alert window
  //         if (
  //           Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //           typeof window.isShowAxiosErrorInConsole === 'function' &&
  //           window.isShowAxiosErrorInConsole(err)
  //         ) {
  //           window.showAxiosErrInConsole(err);
  //         } else {
  //           VueNotifications.error({ message: err });
  //         }
  //
  //         ctx.error = err;
  //         ctx.$store.commit('toggleLoadingCpesSessions');
  //       }
  //     );
  // },
  getCpesSessions(ctx) {
    ctx.$store.commit('toggleLoadingCpesSessions');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();

    const payload = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      timebounds: { start, stop }
    }

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        payload.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        payload.with_childs = true;
      } else {
        payload.location = ctx.filters.location;
        payload.with_childs = ctx.filters.with_childs;
      }
    }

    Vue.axios
      .post(`${window.RESTAPI || ''}/api/stat/session/cpes`, payload)
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('setCpesSessionsList', response.data.data.itemslist);
            if (ctx.statCpeId) {
              ctx.responseStats(this);
            }
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingCpesSessions');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingCpesSessions');
        }
      );
  },

  getCpeStat(ctx) {
    // Получаем статистику по конкретному CPE зв определенный период времени
    ctx.$store.commit('toggleLoadingStats');
    if (!ctx.drPickerDateForStat.startDate || !ctx.drPickerDateForStat.endDate) {
      ctx.drPickerDateForStat.startDate = ctx.drPickerDateForRating.startDate;
      ctx.drPickerDateForStat.endDate = ctx.drPickerDateForRating.endDate;
    }
    const start = moment(ctx.drPickerDateForStat.startDate).unix();
    const stop = moment(ctx.drPickerDateForStat.endDate).unix();

    // Выставляем границы графика по оси X

    const periodStart = ctx.drPickerDateForStat.startDate / 1000;
    let periodStop = ctx.drPickerDateForStat.endDate / 1000;
    if (periodStop * 1000 > Date.now()) {
      periodStop = Date.now() / 1000;
    }
    const minXAxis = new Date(periodStart * 1000);
    const maxXAxis = new Date(periodStop * 1000);

    ctx.txRxChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.txRxRateChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxRateChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.clientsChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.clientsChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.uniqueClientsChart.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.uniqueClientsChart.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.txRxRateChartPerUser.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.txRxRateChartPerUser.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.memoryCharts.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.memoryCharts.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );

    ctx.cpuCharts.xAxis.min = Date.UTC(
      minXAxis.getFullYear(),
      minXAxis.getMonth(),
      minXAxis.getDate(),
      minXAxis.getHours(),
      minXAxis.getMinutes(),
      minXAxis.getSeconds()
    );
    ctx.cpuCharts.xAxis.max = Date.UTC(
      maxXAxis.getFullYear(),
      maxXAxis.getMonth(),
      maxXAxis.getDate(),
      maxXAxis.getHours(),
      maxXAxis.getMinutes(),
      maxXAxis.getSeconds()
    );
    //

    let apiLink;
    let statQuery;
    if (ctx.filters.wlan === 'All wlans') {
      apiLink = '/api/stat/timeline/cpes';
      statQuery = {
        action: 'R',
        ids: [ctx.cpeSelectedId],
        timebounds: { start, stop }
      };
    } else {
      apiLink = '/api/stat/timeline/wlans/cpes';
      statQuery = {
        action: 'R',
        ids: [ctx.filters.wlan],
        items: { [ctx.cpeSelectedId]: {} },
        timebounds: { start, stop }
      };
    }
    Vue.axios.post(`${window.RESTAPI || ''}${apiLink}`, statQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.currentCpeStats = response.data.data.itemslist;
          // Обнуляю данные графиков
          ctx.refreshCharts();

          if (ctx.currentCpeStats.length === 0) {
            ctx.$store.commit('toggleLoadingStats');
            return;
          }
          // Сортирую массив статистики по времени
          ctx.$store.commit('toggleLoadingStats');
          const sortedCpeStats = ctx.currentCpeStats.sort((curr, prev) => {
            if (curr.timestamp > prev.timestamp) {
              return 1;
            }
            if (curr.timestamp < prev.timestamp) {
              return -1;
            }
            return 0;
          });
          // Формирую данные для HC
          sortedCpeStats.forEach((item) => {
            if (item.timestamp == 0) {
              return;
            }
            const time = new Date(item.timestamp * 1000);
            if (item.cpu_load > 1) {
              item.cpu_load = 1;
            }

            // Data for TxRx
            ctx.txRxChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxChart.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
            ]);
            ctx.txRxChart.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat(
                (
                  parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2)) +
                  parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
                ).toFixed(2)
              )
            ]);

            // Data for Rate TxRx
            ctx.txRxRateChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(4))
            ]);
            ctx.txRxRateChart.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(4))
            ]);
            ctx.txRxRateChart.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(4)) +
                parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(4))
            ]);

            ctx.txRxRateChartPerUser.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_tx_rate / item.total_clients / 1000 / 1000).toFixed(4)) : 0
            ]);
            ctx.txRxRateChartPerUser.series[1].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ? parseFloat((item.traffic_rx_rate / item.total_clients / 1000 / 1000).toFixed(4)) : 0
            ]);
            ctx.txRxRateChartPerUser.series[2].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients ?
                parseFloat((item.traffic_tx_rate / item.total_clients / 1000 / 1000).toFixed(4)) +
                  parseFloat((item.traffic_rx_rate / item.total_clients / 1000 / 1000).toFixed(4)) :
                0
            ]);

            // Data for clients
            ctx.clientsChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.total_clients
            ]);

            // Data for unique clients
            ctx.uniqueClientsChart.series[0].data.push([
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              item.unique_clients
            ]);

            // Data for CPU & Memory Load
            let cpuPercent = item.cpu_load * 100;
            cpuPercent = Math.round(cpuPercent * 100) / 100;
            let memPercent = ((item.memory_total - item.memory_free) * 100) / item.memory_total;
            memPercent = Math.round(memPercent * 100) / 100;
            const arrayCPU = [
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              cpuPercent
            ];
            const arrayMemory = [
              Date.UTC(
                time.getFullYear(),
                time.getMonth(),
                time.getDate(),
                time.getHours(),
                time.getMinutes(),
                time.getSeconds()
              ),
              memPercent
            ];
            ctx.cpuCharts.series[0].data.push(arrayCPU);
            ctx.memoryCharts.series[0].data.push(arrayMemory);
          });
        } else if (response.data.status === 'error') {
          ctx.refreshCharts();
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingStats');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingStats');
      }
    );
  }
};
