import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import cpeService from './cpeService';
import wlanService from './wlanService';

const Queries = {
  AUTHORIZED_CLIENTS: { username: { $gt: '' } },
  // как в функции getClientHealthStatus в clients.vue
  //  Red—Poor client devices. Health score range is 1 to 3.
  //  Orange—Fair client devices. Health score range is 4 to 7.
  //  Green—Good client devices. Health score range is 8 to 10
  //  Gray—Inactive client devices. Health score is 0.
  HEALTH_STATUS_POOR: { health_score: { $gte: 1, $lte: 3 } },
  HEALTH_STATUS_FAIR: { health_score: { $gte: 4, $lte: 7 } },
  HEALTH_STATUS_GOOD: { health_score: { $gte: 8, $lte: 10 } }
};

export default {
  getQuery(ctx, typeOfQuery) {
    const query = {
      action: 'R',
      search: ctx.searchValue,
      page: {},
      sort_by: {
        field: 'state',
        order: 1
      },
      q: {}
    };
    if (typeOfQuery === 'paginate') {
      query.page.limit = ctx.limit;
      query.page.offset = ctx.limit * ctx.offset;
    } else if (typeOfQuery === 'update') {
      query.page.limit = ctx.limit + ctx.limit * ctx.offset;
      query.page.offset = 0;
    }

    if (ctx.filters.statusFilter) {
      query.q = Object.assign(query.q, { state: ctx.filters.statusFilter });
    }
    if (ctx.filters.typeFilter) {
      query.q = Object.assign(query.q, { type: ctx.filters.typeFilter });
    }
    if (ctx.filters.frequencyFilter) {
      query.q = Object.assign(query.q, { freq: ctx.filters.frequencyFilter });
    }
    if (ctx.filters.authorizedFilter) {
      query.q = Object.assign(query.q, Queries.AUTHORIZED_CLIENTS);
    }
    if (ctx.filters.healthStatusFilter) {
      // если выбран фильтр по отключенным сбрасываем фильтры health status
      if (ctx.filters.statusFilter !== 'CONNECTED') {
        ctx.filters.healthStatusFilter = '';
      }
      if (ctx.filters.healthStatusFilter === 'Poor') {
        query.q = Object.assign(query.q, Queries.HEALTH_STATUS_POOR);
      }
      if (ctx.filters.healthStatusFilter === 'Fair') {
        query.q = Object.assign(query.q, Queries.HEALTH_STATUS_FAIR);
      }
      if (ctx.filters.healthStatusFilter === 'Good') {
        query.q = Object.assign(query.q, Queries.HEALTH_STATUS_GOOD);
      }
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'location')) {
      query.location = ctx.filters.location;
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'with_childs')) {
      query.with_childs = ctx.filters.with_childs;
    }
    if (Object.prototype.hasOwnProperty.call(query, 'location') && query.location === 'All locations') {
      query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
      query.with_childs = true;
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'cpe_id') && ctx.filters.cpe_id !== '') {
      query.q = Object.assign(query.q, { cpe_id: ctx.filters.cpe_id });
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'wlan_id') && ctx.filters.wlan_id !== '') {
      query.q = Object.assign(query.q, { wlan_id: ctx.filters.wlan_id });
    }
    return query;
  },

  getQueryV2(ctx, typeOfQuery) {
    const query = {
      action: 'R',
      search: ctx.searchValue,
      page: {},
      sort_by: {
        field: 'state',
        order: 1
      },
      q: {}
    };
    if (typeOfQuery === 'paginate') {
      query.page.limit = ctx.limit;
      query.page.offset = ctx.limit * ctx.offset;
    } else if (typeOfQuery === 'update') {
      query.page.limit = ctx.limit + ctx.limit * ctx.offset;
      query.page.offset = 0;
    }

    if (ctx.filters.statusFilter) {
      query.q = Object.assign(query.q, { state: ctx.filters.statusFilter });
    }
    if (ctx.filters.typeFilter) {
      query.q = Object.assign(query.q, { type: ctx.filters.typeFilter });
    }
    if (ctx.filters.frequencyFilter) {
      query.q = Object.assign(query.q, { freq: ctx.filters.frequencyFilter });
    }
    if (ctx.filters.authorizedFilter) {
      query.q = Object.assign(query.q, Queries.AUTHORIZED_CLIENTS);
    }
    if (ctx.filters.healthStatusFilter) {
      // если выбран фильтр по отключенным сбрасываем фильтры health status
      if (ctx.filters.statusFilter !== 'CONNECTED') {
        ctx.filters.healthStatusFilter = '';
      }
      if (ctx.filters.healthStatusFilter === 'Poor') {
        query.q = Object.assign(query.q, Queries.HEALTH_STATUS_POOR);
      }
      if (ctx.filters.healthStatusFilter === 'Fair') {
        query.q = Object.assign(query.q, Queries.HEALTH_STATUS_FAIR);
      }
      if (ctx.filters.healthStatusFilter === 'Good') {
        query.q = Object.assign(query.q, Queries.HEALTH_STATUS_GOOD);
      }
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'location')) {
      query.location = ctx.filters.location;
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'with_childs')) {
      query.with_childs = ctx.filters.with_childs;
    }
    if (Object.prototype.hasOwnProperty.call(query, 'location') && query.location === 'All locations') {
      query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
      query.with_childs = true;
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'cpe_id') && ctx.filters.cpe_id !== '') {
      query.q = Object.assign(query.q, { cpe_id: ctx.filters.cpe_id });
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'wlan_id') && ctx.filters.wlan_id !== '') {
      query.q = Object.assign(query.q, { wlan_id: ctx.filters.wlan_id });
    }
    return query;
  },

  // getQueryForRFP(ctx, typeOfQuery) {
  //   let startDate = (ctx.drPickerDateForRFP.startDate / 1000).toFixed(0);
  //   let endDate = (ctx.drPickerDateForRFP.endDate / 1000).toFixed(0);
  //   startDate = parseInt(startDate, 10);
  //   endDate = parseInt(endDate, 10);
  //   const query = {
  //     action: 'R',
  //     page: {},
  //     search: ctx.searchValueForRFP,
  //     timebounds: { start: startDate, stop: endDate }
  //     // sort_by: {
  //     //   field: 'state',
  //     //   order: 1
  //     // },
  //     // q: {}
  //   };
  //   if (typeOfQuery === 'paginate') {
  //     query.page.limit = ctx.limitRFP;
  //     query.page.offset = ctx.limitRFP * ctx.offsetRFP;
  //   } else if (typeOfQuery === 'update') {
  //     query.page.limit = ctx.limitRFP + ctx.limitRFP * ctx.offsetRFP;
  //     query.page.offset = 0;
  //   }
  //   //
  //   // if (ctx.filtersRFP.statusFilter) {
  //   //   query.q = Object.assign(query.q, { state: ctx.filters.statusFilter });
  //   // }
  //   // if (ctx.filtersRFP.typeFilter) {
  //   //   query.q = Object.assign(query.q, { type: ctx.filters.typeFilter });
  //   // }
  //   // if (ctx.filtersRFP.frequencyFilter) {
  //   //   query.q = Object.assign(query.q, { freq: ctx.filters.frequencyFilter });
  //   // }
  //   // if (ctx.filtersRFP.authorizedFilter) {
  //   //   query.q = Object.assign(query.q, Queries.AUTHORIZED_CLIENTS);
  //   // }
  //   return query;
  // },
  getQueryForRFP(ctx, typeOfQuery) {
    let startDate = (ctx.drPickerDateForRFP.startDate / 1000).toFixed(0);
    let endDate = (ctx.drPickerDateForRFP.endDate / 1000).toFixed(0);
    startDate = parseInt(startDate, 10);
    endDate = parseInt(endDate, 10);
    const query = {
      action: 'R',
      page: {},
      search: ctx.searchValueForRFP,
      timebounds: { start: startDate, stop: endDate }
      // sort_by: {
      //   field: 'state',
      //   order: 1
      // },
      // q: {}
    };
    if (typeOfQuery === 'paginate') {
      query.page.limit = ctx.limitRFP;
      query.page.offset = ctx.limitRFP * ctx.offsetRFP;
    } else if (typeOfQuery === 'update') {
      query.page.limit = ctx.limitRFP + ctx.limitRFP * ctx.offsetRFP;
      query.page.offset = 0;
    }
    //
    // if (ctx.filtersRFP.statusFilter) {
    //   query.q = Object.assign(query.q, { state: ctx.filters.statusFilter });
    // }
    // if (ctx.filtersRFP.typeFilter) {
    //   query.q = Object.assign(query.q, { type: ctx.filters.typeFilter });
    // }
    // if (ctx.filtersRFP.frequencyFilter) {
    //   query.q = Object.assign(query.q, { freq: ctx.filters.frequencyFilter });
    // }
    // if (ctx.filtersRFP.authorizedFilter) {
    //   query.q = Object.assign(query.q, Queries.AUTHORIZED_CLIENTS);
    // }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'location')) {
      query.location = ctx.filters.location;
    }
    if (Object.prototype.hasOwnProperty.call(ctx.filters, 'with_childs')) {
      query.with_childs = ctx.filters.with_childs;
    }
    if (Object.prototype.hasOwnProperty.call(query, 'location') && query.location === 'All locations') {
      query.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
      query.with_childs = true;
    }
    return query;
  },

  getOnlyClients(ctx) {
    Vue.axios.post(`${window.RESTAPI || ''}/api/clients`, { action: 'R', location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx) }).then(
      (response) => {
        if (response.data.status === 'success') {
          // ctx.clientsList = response.data.data.itemslist;
          ctx.$store.commit('setClientsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  getPaginatedClientsRFP(ctx) {
    ctx.$store.commit('toggleLoadingClientsRFP');
    Vue.axios.post(`${window.RESTAPI || ''}/api/client/rfp`, this.getQueryForRFP(ctx, 'paginate')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.$store.commit('toggleLoadingClientsRFP');
          ctx.totalResultRFP = response.data.data.total;
          // ctx.clientsList = response.data.data.itemslist;
          ctx.$store.commit('pushClientListRFP', response.data.data.itemslist);
          if (ctx.$store.state.clientsRFPList.length >= response.data.data.total) {
            ctx.canLoadMoreRFP = false;
          } else {
            ctx.canLoadMoreRFP = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingClientsRFP');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.$store.commit('toggleLoadingClientsRFP');
        ctx.error = err;
      }
    );
  },

  getPaginatedClients(ctx) {
    ctx.$store.commit('toggleLoadingClients');
    Vue.axios.post(`${window.RESTAPI || ''}/api/clients`, this.getQuery(ctx, 'paginate')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          // ctx.clientsList = response.data.data.itemslist;
          const cpesIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!cpesIds.includes(client.cpe_id)) {
              cpesIds.push(client.cpe_id);
            }
          });
          cpeService.getSelectedCpes(ctx, cpesIds);

          const wlansIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!wlansIds.includes(client.wlan_id)) {
              wlansIds.push(client.wlan_id);
            }
          });
          wlanService.getSelectedWlans(ctx, wlansIds);
          ctx.$store.commit('pushClientList', response.data.data.itemslist);
          ctx.$store.commit('toggleLoadingClients');
          if (ctx.$store.state.clientsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingClients');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.$store.commit('toggleLoadingClients');
        ctx.error = err;
      }
    );
  },

  getPaginatedClientsV2(ctx) {
    ctx.$store.commit('toggleLoadingClients');
    Vue.axios.post(`${window.RESTAPI || ''}/api/clients`, this.getQueryV2(ctx, 'paginate')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          // ctx.clientsList = response.data.data.itemslist;
          const cpesIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!cpesIds.includes(client.cpe_id)) {
              cpesIds.push(client.cpe_id);
            }
          });
          if (cpesIds.length){
            cpeService.getSelectedCpes(ctx, cpesIds)
          }

          const wlansIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!wlansIds.includes(client.wlan_id)) {
              wlansIds.push(client.wlan_id);
            }
          });
          // console.log('wlans length', wlansIds)
          if (wlansIds.length){
            wlanService.getSelectedWlans(ctx, wlansIds);
          }
          ctx.$store.commit('pushClientList', response.data.data.itemslist);
          ctx.$store.commit('toggleLoadingClients');
          if (ctx.$store.state.clientsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingClients');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.$store.commit('toggleLoadingClients');
        ctx.error = err;
      }
    );
  },

  refreshClients(ctx, spinner) {
    if (spinner) {
      ctx.$store.commit('toggleLoadingClients');
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/clients`, this.getQuery(ctx, 'update')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          const cpesIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!cpesIds.includes(client.cpe_id)) {
              cpesIds.push(client.cpe_id);
            }
          });
          cpeService.getSelectedCpes(ctx, cpesIds);

          const wlansIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!wlansIds.includes(client.wlan_id)) {
              wlansIds.push(client.wlan_id);
            }
          });
          if (ctx.$store.state.clientsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          wlanService.getSelectedWlans(ctx, wlansIds);
          ctx.searchSpinner = false;
          if (spinner) {
            ctx.$store.commit('toggleLoadingClients');
          }
          ctx.$store.commit('setClientsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          ctx.searchSpinner = false;
          if (spinner) {
            ctx.$store.commit('toggleLoadingClients');
          }
          if (response.data.description === 'not found') {
            ctx.$store.commit('setClientsList', []);
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        }
      },
      (err) => {
        ctx.searchSpinner = false;
        ctx.$store.commit('toggleLoadingClients');

        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  refreshClientsV2(ctx, spinner) {
    if (spinner) {
      ctx.$store.commit('toggleLoadingClients');
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/clients`, this.getQueryV2(ctx, 'update')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResult = response.data.data.total;
          const cpesIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!cpesIds.includes(client.cpe_id)) {
              cpesIds.push(client.cpe_id);
            }
          });
          if (cpesIds.length){
            cpeService.getSelectedCpes(ctx, cpesIds);
          }


          const wlansIds = [];
          response.data.data.itemslist.forEach((client) => {
            if (!wlansIds.includes(client.wlan_id)) {
              wlansIds.push(client.wlan_id);
            }
          });
          if (ctx.$store.state.clientsList.length >= response.data.data.total) {
            ctx.canLoadMore = false;
          } else {
            ctx.canLoadMore = true;
          }
          if (wlansIds.length) {
            wlanService.getSelectedWlans(ctx, wlansIds);
          }

          ctx.searchSpinner = false;
          if (spinner) {
            ctx.$store.commit('toggleLoadingClients');
          }
          ctx.$store.commit('setClientsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          ctx.searchSpinner = false;
          if (spinner) {
            ctx.$store.commit('toggleLoadingClients');
          }
          if (response.data.description === 'not found') {
            ctx.$store.commit('setClientsList', []);
          } else {
            VueNotifications.error({ message: response.data.description });
          }
        }
      },
      (err) => {
        ctx.searchSpinner = false;
        ctx.$store.commit('toggleLoadingClients');

        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  refreshClientsForRFP(ctx, spinner) {
    if (spinner) {
      ctx.$store.commit('toggleLoadingClientsRFP');
    }

    Vue.axios.post(`${window.RESTAPI || ''}/api/client/rfp`, this.getQueryForRFP(ctx, 'update')).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.totalResultRFP = response.data.data.total;
          if (ctx.$store.state.clientsRFPList.length >= response.data.data.total) {
            ctx.canLoadMoreRFP = false;
          } else {
            ctx.canLoadMoreRFP = true;
          }
          ctx.searchSpinner = false;
          if (spinner) {
            ctx.$store.commit('toggleLoadingClientsRFP');
          }
          ctx.$store.commit('setClientsRFPList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          ctx.searchSpinner = false;
          if (spinner) {
            ctx.$store.commit('toggleLoadingClientsRFP');
          }
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        ctx.searchSpinner = false;
        ctx.$store.commit('toggleLoadingClientsRFP');

        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },

  getSelectedClients(ctx, clientsIds) {
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      q: { _id: { $in: clientsIds } }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/clients`, query).then(
      (response) => {
        if (response.data.status === 'success') {
          // ctx.clientsList = response.data.data.itemslist;
          ctx.$store.commit('setClientsList', response.data.data.itemslist);
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },

  updateClient(ctx) {
    ctx.$store.commit('toggleLoadingClients');
    const updateClientsQuery = { action: 'U', items: { [ctx.updatedClient.mac]: ctx.updatedClient } };
    Vue.axios.post(`${window.RESTAPI || ''}/api/clients`, updateClientsQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('clients.notificationClientUpdated') });
          this.refreshClients(ctx);
          ctx.$store.commit('toggleLoadingClients');
        } else if (response.data.status === 'error') {
          ctx.$store.commit('toggleLoadingClients');
          VueNotifications.error({ message: response.data.description });
        }
        if (ctx.$store.state.clientsList.length >= response.data.data.total) {
          ctx.canLoadMore = false;
        } else {
          ctx.canLoadMore = true;
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.$store.commit('toggleLoadingClients');
        ctx.error = err;
      }
    );
  }
};
