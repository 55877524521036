<template>
  <div class="row">
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-8 col-sm-8 filters">-->
<!--          <div class="filters__search">-->
<!--            <div class="input-group input-group-sm">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-search"></i>-->
<!--              </span>-->
<!--              <input-->
<!--                @input="searchInputHandler"-->
<!--                id="search"-->
<!--                name="search"-->
<!--                :placeholder="$t('aps.search')"-->
<!--                class="form-control filters__input"-->
<!--                v-model="searchString"-->
<!--              />-->
<!--              <span class="clear-input text-muted">-->
<!--                <i class="fa fa-remove" @click="searchClear"></i>-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="filters__multiple mb-1">-->
<!--            &lt;!&ndash;            <div class="input-group input-group-sm filters__sort mr-1">&ndash;&gt;-->
<!--            &lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--            &lt;!&ndash;                <i class="fa fa-sort-amount-desc icon-sort"></i>&ndash;&gt;-->
<!--            &lt;!&ndash;              </span>&ndash;&gt;-->
<!--            &lt;!&ndash;              <select class="form-control form-control-sm filters__field" v-model="sortByFilter">&ndash;&gt;-->
<!--            &lt;!&ndash;                <option&ndash;&gt;-->
<!--            &lt;!&ndash;                  :selected="true"&ndash;&gt;-->
<!--            &lt;!&ndash;                  value="Disconnected"&ndash;&gt;-->
<!--            &lt;!&ndash;                >{{ $t('monitor.sessionDisconnected') }}</option>&ndash;&gt;-->
<!--            &lt;!&ndash;                <option :selected="true" value="Connected">{{ $t('monitor.sessionConnected') }}</option>&ndash;&gt;-->
<!--            &lt;!&ndash;              </select>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--&lt;!&ndash;              <select&ndash;&gt;-->
<!--&lt;!&ndash;                class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="searchLocationId"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="newSearch"&ndash;&gt;-->
<!--&lt;!&ndash;              >&ndash;&gt;-->
<!--&lt;!&ndash;                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                </option>&ndash;&gt;-->
<!--&lt;!&ndash;              </select>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="  d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="newSearch"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->

<!--                                      <div class="input-group-for-location-select-in-monitoringWiFiAnalyzer&#45;&#45;filters">-->


<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-monitoringWiFiAnalyzer&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="newSearch"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->


<!--              </div>-->




<!--&lt;!&ndash;            <button class="btn btn-primary btn-sm custom-margin-left" @click="newSearch">&ndash;&gt;-->
<!--&lt;!&ndash;              {{ $t('general.applyFilters') }}&ndash;&gt;-->
<!--&lt;!&ndash;            </button>&ndash;&gt;-->
<!--          </div>-->
<!--                    <div v-if="isFiltersActive" class="mb-1">-->
<!--              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

        <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12 col-sm-12 filters">
          <div class="row">
            <div class="col-lg-3 mb-h pr-0">
                        <div class="filters__search">
            <div class="input-group input-group-sm">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input
                @input="searchInputHandler"
                id="search"
                name="search"
                :placeholder="$t('aps.search')"
                class="form-control filters__input"
                v-model="searchString"
              />
              <span class="clear-input text-muted">
                <i class="fa fa-remove" @click="searchClear"></i>
              </span>
            </div>
          </div>

            </div>
            <div class="col-lg-3 mb-h pr-0">
                                                    <div class="input-group-for-location-select-in-monitoringWiFiAnalyzer--filters">


                                                                   <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-monitoringWiFiAnalyzer--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="newSearch"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>


              </div>
            </div>
          </div>

<!--          <div class="filters__multiple mb-1">-->
<!--            &lt;!&ndash;            <div class="input-group input-group-sm filters__sort mr-1">&ndash;&gt;-->
<!--            &lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--            &lt;!&ndash;                <i class="fa fa-sort-amount-desc icon-sort"></i>&ndash;&gt;-->
<!--            &lt;!&ndash;              </span>&ndash;&gt;-->
<!--            &lt;!&ndash;              <select class="form-control form-control-sm filters__field" v-model="sortByFilter">&ndash;&gt;-->
<!--            &lt;!&ndash;                <option&ndash;&gt;-->
<!--            &lt;!&ndash;                  :selected="true"&ndash;&gt;-->
<!--            &lt;!&ndash;                  value="Disconnected"&ndash;&gt;-->
<!--            &lt;!&ndash;                >{{ $t('monitor.sessionDisconnected') }}</option>&ndash;&gt;-->
<!--            &lt;!&ndash;                <option :selected="true" value="Connected">{{ $t('monitor.sessionConnected') }}</option>&ndash;&gt;-->
<!--            &lt;!&ndash;              </select>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->






<!--&lt;!&ndash;            <button class="btn btn-primary btn-sm custom-margin-left" @click="newSearch">&ndash;&gt;-->
<!--&lt;!&ndash;              {{ $t('general.applyFilters') }}&ndash;&gt;-->
<!--&lt;!&ndash;            </button>&ndash;&gt;-->
<!--          </div>-->
                    <div class="mb-h" v-if="isFiltersActive" >
              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </button>
            </div>
        </div>
      </div>
    </div>





    <div class="col-lg-12">
      <div class="card border-info">
        <div class="card-body text-info">
          <p class="card-text">{{ $t('monitor.analyzerInfoMessage') }}</p>
        </div>
      </div>
      <div class="cpes" :class="{ 'stat-visible': statVisible }">
        <div class="cpes-rating-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-monitor-wifianalyzer" v-if="isCpesLoading"></div>-->
                  <div v-if="isCpesLoading"><Loader-spinner /></div>
                  <div class="card-header">
                    <i class="fa fa-bar-chart"></i>
                    {{ $t('general.cpes') }}
                    <!--                    <div class="float-right" v-if="!statVisible">-->
                    <!--                      <date-picker-->
                    <!--                        v-model="drPickerDateForRating"-->
                    <!--                        :onChange="handleDateChangeForRating.bind(this)"-->
                    <!--                      />-->

                    <!--                      <download-extract-->
                    <!--                        v-if="cpesSessionsList"-->
                    <!--                        class="ml-h"-->
                    <!--                        :data="formatToCSV(filterItems(cpesSessionsList))"-->
                    <!--                        filename="sessions"-->
                    <!--                      />-->
                    <!--                    </div>-->
                  </div>

                  <div class="card-block">
                    <table class="table">
                      <thead>
                        <tr>
                          <th v-if="!statVisible"></th>
                          <th v-if="!statVisible">{{ $t('general.model') }}</th>
                          <th>{{ $t('general.name') }}</th>
                          <!--                          <th>{{ $t('monitor.sessions') }}</th>-->
                          <th v-if="!statVisible">{{ $t('general.location') }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(cpe, index) in filterItems(cpesBackgroundScaningEnabledList)"
                          :data-index="index"
                          :data-id="cpe.id"
                          :key="cpe.id"
                          v-if="cpe.name && !isDisable"
                          class="cpe-row"
                          :class="{ selected: statCpeId === cpe.id }"
                          @click="toggleEdit"
                        >
                          <td v-if="!statVisible">{{ index + 1 }}</td>
                          <td v-if="!statVisible">
                            <span v-if="cpe.model">{{ cpe.model.name }}</span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="responsive-row">
                            <span v-if="cpe.name">{{ cpe.name }}</span>
                            <span v-else class="text-muted">
                              No data
                              <br />
                              <small v-if="!statVisible">(cpe id: {{ cpe.id }} )</small>
                            </span>
                          </td>
                          <!--                          <td>-->
                          <!--                            <span v-if="cpe.sessions">-->
                          <!--                              <span-->
                          <!--                                class="text-danger"-->
                          <!--                                v-if="percentData(fullPeriod, totalOff(cpe.sessions)) > 50"-->
                          <!--                              >-->
                          <!--                                <i class="fa fa-exclamation"></i>-->
                          <!--                              </span>-->
                          <!--                              <span class>{{ (fullPeriod - totalOff(cpe.sessions)) | momentnow }}</span>-->
                          <!--                              <small-->
                          <!--                                :class="{-->
                          <!--                                  'text-success': percentData(fullPeriod, totalOff(cpe.sessions)) < 20,-->
                          <!--                                  'text-warning':-->
                          <!--                                    percentData(fullPeriod, totalOff(cpe.sessions)) >= 20 &&-->
                          <!--                                    percentData(fullPeriod, totalOff(cpe.sessions)) <= 50,-->
                          <!--                                  'text-danger': percentData(fullPeriod, totalOff(cpe.sessions)) > 50-->
                          <!--                                }"-->
                          <!--                                v-if="fullPeriod - totalOff(cpe.sessions)"-->
                          <!--                              >({{ (100 - percentData(fullPeriod, totalOff(cpe.sessions))).toFixed(1) }}%)</small>-->
                          <!--                            </span>-->
                          <!--                            <span class="text-danger" v-else>No sessions</span>-->
                          <!--                          </td>-->
                          <td v-if="!statVisible" class="responsive-row">{{ locationName(cpe.base_location) }}</td>
                          <td v-if="!statVisible" class="action-row">
                            <i
                              class="fa fa-wifi fa-lg mr-q text-primary user-action"
                              v-if="cpe.connected && !isDisable"
                              data-action="scan"
                            ></i>
                          </td>
                        </tr>

                        <tr v-if="!filterItems(cpesBackgroundScaningEnabledList).length">
                          <td colspan="9" class="no-data-row">
                            <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      v-if="canLoadMore && !isCpesLoading && cpesBackgroundScaningEnabledList.length && !statVisible"
                      class="card-block text-center p-0"
                    >
                      <button class="btn btn-link new-modern-style-btn-load-more-btn" @click="loadMore()">
                        {{ this.$t('portal.paginationLoad') }}
                        <span v-if="this.limit < this.totalResult - this.cpesBackgroundScaningEnabledList.length">
                          {{ this.limit }}
                        </span>
                        <span v-else>{{ this.totalResult - this.cpesBackgroundScaningEnabledList.length }}</span>
                        {{ this.$t('portal.paginationAdsOutOf') }}
                        {{ this.totalResult - this.cpesBackgroundScaningEnabledList.length }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cpes-stat" v-if="statVisible">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header" style="border-bottom: 0px">
                  <img class="img img-responsive cpe-logo" src="/static/img/router.svg" alt />
                  <span v-if="cpeStatCurrent">{{ cpeStatCurrent.name }}</span>
                  <span v-else>{{ cpeSelectedId }}</span>

                  <button
                    type="button"
                    class="btn btn-outline-secondary btn-sm float-right new-modern-style-btn-close-panel"
                    @click="disableStatMode"
                  >
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="card-block pl-0  pt-0 pr-0 ">&nbsp;</div>
                <!--                <div class="card-block">-->
                <!--                  <div class="row">-->
                <!--                    <div class="col-lg-12">-->
                <!--                      <div class="float-right">-->
                <!--                        <date-picker-->
                <!--                          v-model="drPickerDateForRating"-->
                <!--                          :onChange="handleDateChangeForRating.bind(this)"-->
                <!--                        />-->
                <!--                        <download-extract-->
                <!--                          v-if="getSession(cpesSessionsList, cpeSelectedId).length"-->
                <!--                          class="ml-h"-->
                <!--                          :data="getSession(cpesSessionsList, cpeSelectedId)"-->
                <!--                          :filename="'session_'+cpeSelectedId"-->
                <!--                        />-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="row">-->
                <!--                  <div class="col-lg-12">-->
                <!--                    <div class="row mt-1">-->
                <!--                      <div class="col-lg-12">-->
                <!--                        <highcharts :options="sessionChart" ref="highcharts"></highcharts>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>

              <div
                class="card mt-2"
                v-if="scanResult && scanResult.scanlist && Object.keys(scanResult.scanlist).length"
              >
                <!--                <div-->
                <!--                  class="loader loader-backdrop loader-backdrop-monitor-wifianalyzer"-->
                <!--                  v-if="isCpesScannerLoading"-->
                <!--                ></div>-->
                <div v-if="isCpesScannerLoading"><Loader-spinner /></div>
                <div class="card-header">
                  <i class="fa fa-wifi"></i>
                  {{ $t('sidebar.wifiAnalyzer') }}
                </div>
                <div class="card-header">{{ $t('monitor.lastScanDate') }} {{ scanResult.timestamp | moment }}</div>
                <div v-for="(item, key) in scanResult.scanlist">
                  <div class="card-header">
                    <b>radio {{ key }}</b>
                  </div>

                  <div
                    class="card-block"
                    v-if="chartSeries[key] && ((chartSeries[key].two_ghz_scanner.series ||  chartSeries[key].five_ghz_scanner.series)&&(chartSeries[key].two_ghz_scanner.series.length ||  chartSeries[key].five_ghz_scanner.series.length))"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row mt-1">
                          <div
                            class="col-lg-12"
                            v-if="
                              chartSeries[key].two_ghz_scanner &&
                              chartSeries[key].two_ghz_scanner.series &&
                              chartSeries[key].two_ghz_scanner.series.length
                            "
                          >
                            <highcharts :options="chartSeries[key].two_ghz_scanner" ref="highcharts"></highcharts>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div
                            class="col-lg-12"
                            v-if="
                              chartSeries[key].five_ghz_scanner &&
                              chartSeries[key].five_ghz_scanner.series &&
                              chartSeries[key].five_ghz_scanner.series.length
                            "
                          >
                            <highcharts :options="chartSeries[key].five_ghz_scanner" ref="highcharts"></highcharts>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-block" v-else>{{ $t('general.noDataToDisplay') }}</div>

                  <div class="card-block" v-if="scanResult.scanlist[key]">
                    <div class="wlan-settings-block">
                      <div
                        class="wlan-settings-block__header"
                        @click.prevent="showScanResult"
                        v-bind:data-radio-number="key"
                      >
                        <span class="h6 mb-2 mr-1" v-bind:data-radio-number="key">
                          {{ $t('wifiAnalyzer.wlanChars') }}
                        </span>
                        <button
                          class="btn btn-link float-right new-modern-style-btn-other-btn"
                          v-bind:data-radio-number="key"
                          v-if="!isScanResultShow[key]"
                        >
                          <i class="icon-arrow-left" v-bind:data-radio-number="key"></i>
                        </button>
                        <button
                          class="btn btn-link float-right new-modern-style-btn-other-btn"
                          v-bind:data-radio-number="key"
                          v-if="isScanResultShow[key]"
                        >
                          <i class="icon-arrow-down" v-bind:data-radio-number="key"></i>
                        </button>
                      </div>
                      <transition name="wsettings">
                        <div class v-if="isScanResultShow[key]">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>
                                  <input
                                    type="checkbox"
                                    name="checkbox2"
                                    value="option"
                                    v-bind:data-radio-number="key"
                                    @click="checkAllBssids"
                                    id="checkAll"
                                    :checked="checkAllState(key)"
                                  />
                                </th>
                                <th>BSSID</th>
                                <th>SSID</th>
                                <th>{{ $t('wifiAnalyzer.signal') }}</th>
                                <th>{{ $t('wlans.security') }}</th>
                                <th>{{ $t('wifiAnalyzer.channel') }}</th>
                                <th>{{ $t('wifiAnalyzer.quality') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in scanResult.scanlist[key]"
                                :data-index="index"
                                :key="item.bssid"
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    name="checkbox2"
                                    value="option"
                                    :checked="isSsidChecked(key, item.bssid)"
                                    @click="toggleBssid(key, item.bssid)"
                                  />
                                </td>
                                <td class="responsive-row">{{ item.bssid }}</td>
                                <td class="responsive-row">{{ item.ssid }}</td>
                                <td class="responsive-row">{{ item.signal }} dBm</td>
                                <td class="responsive-row">{{ item.encryption.description }}</td>
                                <td class="responsive-row">{{ item.channel }}</td>
                                <td class="responsive-row">{{ item.quality }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mt-2" v-else>
                <div class="card-header">
                  {{ $t('general.noDataToDisplay') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import cpeService from '../../services/cpeService';
import statCpeService from '../../services/statCpeService';
import commonService from '../../services/commonService';
import Filters from '../../filters';
// import helpers from '../../helpers';
import DatePicker from '../../components/date-picker.vue';
import helpers from '../../helpers';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import locationService from '../../services/locationService';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

// Если изменяются значения этих 2 массивов (two_ghz_chanlist и five_ghz_chanlist)
// то не забыть поставить min и max в настройках xAxis для обоих чартов
//        xAxis: {
//          categories: two_ghz_chanlist,
//          min: 0,
//          max: 13
//        },
// где min - индекс минимального элемента массива, а max индекс максимального

// eslint-disable-next-line camelcase
const two_ghz_chanlist = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'];
// eslint-disable-next-line camelcase
const five_ghz_chanlist = [
  '7',
  '8',
  '9',
  '11',
  '12',
  '16',
  '34',
  '36',
  '38',
  '40',
  '42',
  '44',
  '46',
  '48',
  '50',
  '52',
  '54',
  '56',
  '58',
  '60',
  '62',
  '64',
  '100',
  '102',
  '104',
  '106',
  '108',
  '110',
  '112',
  '114',
  '116',
  '118',
  '120',
  '122',
  '124',
  '126',
  '128',
  '132',
  '134',
  '136',
  '138',
  '140',
  '142',
  '144',
  '149',
  '151',
  '153',
  '155',
  '157',
  '159',
  '161',
  '165',
  '169',
  '173',
  '183',
  '184',
  '185',
  '187',
  '188',
  '189',
  '192',
  '196'
];
// eslint-disable-next-line no-unused-vars
const chanFreqMap2 = {
  1: 2412,
  2: 2417,
  3: 2422,
  4: 2427,
  5: 2432,
  6: 2437,
  7: 2442,
  8: 2447,
  9: 2452,
  10: 2457,
  11: 2462,
  12: 2467,
  13: 2472,
  14: 2484
};

// eslint-disable-next-line no-unused-vars
const chanFreqMap5 = {
  7: 5035,
  8: 5040,
  9: 5045,
  11: 5055,
  12: 5060,
  16: 5080,
  34: 5170,
  36: 5180,
  38: 5190,
  40: 5200,
  42: 5210,
  44: 5220,
  46: 5230,
  48: 5240,
  50: 5250,
  52: 5260,
  54: 5270,
  56: 5280,
  58: 5290,
  60: 5300,
  62: 5310,
  64: 5320,
  100: 5500,
  102: 5510,
  104: 5520,
  106: 5530,
  108: 5540,
  110: 5550,
  112: 5560,
  114: 5570,
  116: 5580,
  118: 5590,
  120: 5600,
  122: 5610,
  124: 5620,
  126: 5630,
  128: 5640,
  132: 5660,
  134: 5670,
  136: 5680,
  138: 5690,
  140: 5700,
  142: 5710,
  144: 5720,
  149: 5745,
  151: 5755,
  153: 5765,
  155: 5775,
  157: 5785,
  159: 5795,
  161: 5805,
  165: 5825,
  169: 5845,
  173: 5865,
  183: 4915,
  184: 4920,
  185: 4925,
  187: 4935,
  188: 4940,
  189: 4945,
  192: 4960,
  196: 4980
};

// eslint-disable-next-line no-unused-vars
const freqChanMap = {
  2412: 1,
  2417: 2,
  2422: 3,
  2427: 4,
  2432: 5,
  2437: 6,
  2442: 7,
  2447: 8,
  2452: 9,
  2457: 10,
  2462: 11,
  2467: 12,
  2472: 13,
  2484: 14,
  5035: 7,
  5040: 8,
  5045: 9,
  5055: 11,
  5060: 12,
  5080: 16,
  5170: 34,
  5180: 36,
  5190: 38,
  5200: 40,
  5210: 42,
  5220: 44,
  5230: 46,
  5240: 48,
  5250: 50,
  5260: 52,
  5270: 54,
  5280: 56,
  5290: 58,
  5300: 60,
  5310: 62,
  5320: 64,
  5500: 100,
  5510: 102,
  5520: 104,
  5530: 106,
  5540: 108,
  5550: 110,
  5560: 112,
  5570: 114,
  5580: 116,
  5590: 118,
  5600: 120,
  5610: 122,
  5620: 124,
  5630: 126,
  5640: 128,
  5660: 132,
  5670: 134,
  5680: 136,
  5690: 138,
  5700: 140,
  5710: 142,
  5720: 144,
  5745: 149,
  5755: 151,
  5765: 153,
  5775: 155,
  5785: 157,
  5795: 159,
  5805: 161,
  5825: 165,
  5845: 169,
  5865: 173,
  4915: 183,
  4920: 184,
  4925: 185,
  4935: 187,
  4940: 188,
  4945: 189,
  4960: 192,
  4980: 196
};

export default {
  name: 'WifiAnalyzer',
  components: { DatePicker, SwitchComponent, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors },
  data() {
    return {
      delayTimer: null,
      showLocationFilter: true,
      locationInFiltersData: [],
      limit: 30, // такой же нужно поставить в функции newSearch
      searchString: '',
      searchLocationId: 'All locations',
      offset: 0,
      offsetforRequest: 0,
      totalResult: false,
      canLoadMore: true,
      chartSeries: {},
      cpesObject: {},
      searchQuery: '',
      isScanResultShow: {},
      selectedCpes: [],
      filteredCpes: [],
      statCpeId: '',
      currentCpeStats: [],
      scanResult: {},
      ssidsToDisplay: [],
      ssidsToDisplayV2: {},
      drPickerDateForRating: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      drPickerDateForStat: {
        startDate: '',
        endDate: ''
      },
      filters: {
        query: '',
        interfacesFilter: '',
        connectedFilter: '',
        location: 'All locations',
        wlan: 'All wlans',
        with_childs: true
      },
      sortByFilter: 'Connected',
      two_ghz_scanner: {
        chart: {
          type: 'area',
          spacingBottom: 30,
          zoomType: 'x',
          height: 400
        },
        title: {
          text: '2.4 GHz'
        },
        subtitle: {
          text: '',
          floating: true,
          align: 'right',
          verticalAlign: 'bottom',
          y: 15
        },
        xAxis: {
          categories: two_ghz_chanlist,
          min: 0,
          max: 13
        },
        yAxis: {
          title: {
            text: 'dBm'
          },
          max: 0,
          labels: {
            formatter() {
              return this.value;
            }
          }
        },
        tooltip: {
          // Тултипы теперь формируются в this.getTooltipWithi18n, которая запускается из created()
          // formatter () {
          //   return `<b>${this.series.name}</b><br/>` +
          //     '<span></span>' +
          //     `<span>Signal: </span>${this.series.userOptions.signal} dBm<br><br>` +
          //     `<span>Channel: </span>${this.series.userOptions.channel} <br>` +
          //     `<span>Frequency: </span>${this.series.userOptions.freq} MHz <br> ` +
          //     `<span>Channel width: </span>${this.series.userOptions.width} MHz <br> ` +
          //     `<span>Offset: </span>${this.series.userOptions.offset} <br>`
          // }
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: true,
                lineWidth: 7,
                opacity: 0.9
              }
            }
          },
          area: {
            fillOpacity: 0.5,
            threshold: null
          }
        },
        credits: {
          enabled: false
        },
        series: []
      },
      five_ghz_scanner: {
        chart: {
          type: 'area',
          spacingBottom: 30,
          zoomType: 'x',
          height: 400
        },
        title: {
          text: '5 GHz'
        },
        subtitle: {
          text: '',
          floating: true,
          align: 'right',
          verticalAlign: 'bottom',
          y: 15
        },
        xAxis: {
          categories: five_ghz_chanlist,
          min: 0,
          max: 61
        },
        yAxis: {
          title: {
            text: 'dBm'
          },
          max: 0,
          labels: {
            formatter() {
              return this.value;
            }
          }
        },
        tooltip: {
          // Тултипы теперь формируются в this.getTooltipWithi18n, которая запускается из created()
          // formatter () {
          //   return `<b>${this.series.name}</b><br/>` +
          //     '<span></span>' +
          //     `<span>Signal: </span>${this.series.userOptions.signal} dBm<br><br>` +
          //     `<span>Channel: </span>${this.series.userOptions.channel} <br>` +
          //     `<span>Frequency: </span>${this.series.userOptions.freq} MHz <br> ` +
          //     `<span>Channel width: </span>${this.series.userOptions.width} MHz <br> ` +
          //     `<span>Offset: </span>${this.series.userOptions.offset} <br>`
          // }
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: true,
                lineWidth: 7,
                opacity: 0.9
              }
            }
          },
          area: {
            fillOpacity: 0.5,
            threshold: null
          }
        },
        credits: {
          enabled: false
        },
        series: []
      },
      sessionChart: {
        chart: {
          type: 'area',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('monitor.cpesState'),
          x: -20 // center
        },
        tooltip: {
          pointFormat: '<b>{series.name}</b>'
        },
        xAxis: {
          type: 'datetime',
          min: '',
          max: ''
        },
        yAxis: {
          title: {
            text: ''
          },
          minTickInterval: 1,
          gridLineWidth: 0,
          categories: ['', ''],
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#cccccc'
            }
          ],
          min: 0,
          max: 1
        },
        series: [
          {
            name: 'Connected',
            data: [],
            color: '#81C784'
          },
          {
            name: 'Disconnected',
            data: [],
            color: '#E57373'
          }
        ],

        step: 'left'
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isFiltersActive() {
      return (
        this.filters.query !== '' ||
          this.filters.interfacesFilter !== '' ||
          this.filters.connectedFilter !== '' ||
          this.filters.location !== 'All locations' ||
          this.filters.wlan !== 'All wlans' ||
          this.searchLocationId !== 'All locations' ||
          this.sortByFilter !== 'Connected' || this.searchString !== ''
      );
    },
    isCpesScannerLoading() {
      return this.$store.state.loadingCpesScanner;
    },
    statVisible() {
      return this.$store.state.statCpeId;
    },
    isCpesLoading() {
      return this.$store.state.loadingCpesForWifiAnalyze;
    },
    isCpesRatingLoading() {
      return this.$store.state.loadingCpesRating;
    },

    cpesBackgroundScaningEnabledList() {
      const { cpesListForWifiAnalyze } = this.$store.state;
      const cpesBackgroundScaningEnabledList = cpesListForWifiAnalyze;
      // if (cpesList.filter === undefined) {
      //   return undefined;
      // }
      // const cpesBackgroundScaningEnabledList = cpesList.filter((cpe) => {
      //   if (cpe.config.wifi !== undefined) {
      //     for (const wifiInterface of cpe.config.wifi) {
      //       if (wifiInterface.scanningconfig.enabled === true) {
      //         return true;
      //       }
      //     }
      //   }
      //   return false;
      // });
      return cpesBackgroundScaningEnabledList;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    cpeSelectedId() {
      this.statCpeId = this.$store.state.statCpeId;
      return this.$store.state.statCpeId;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    cpeStatCurrent() {
      const { cpesListForWifiAnalyze } = this.$store.state;
      let cpeObj;
      cpesListForWifiAnalyze.forEach((cpe) => {
        if (cpe.id === this.cpeSelectedId) {
          cpeObj = cpe;
        }
      });
      return cpeObj;
    }
  },
  methods: {
    getTooltipWithi18n() {
      if (this.$i18n.locale === 'ru') {
        return function () {
          return (
            `<b>${this.series.name}</b><br/>` +
            '<span></span>' +
            `<span>Сигнал: </span>${this.series.userOptions.signal} dBm<br><br>` +
            `<span>Канал: </span>${this.series.userOptions.channel} <br>` +
            `<span>Частота </span>${this.series.userOptions.freq} MHz <br> ` +
            `<span>Ширина: </span>${this.series.userOptions.width} MHz <br> ` +
            `<span>Смещение: </span>${this.series.userOptions.offset} <br>`
          );
        };
      }
      return function () {
        return (
          `<b>${this.series.name}</b><br/>` +
          '<span></span>' +
          `<span>Signal: </span>${this.series.userOptions.signal} dBm<br><br>` +
          `<span>Channel: </span>${this.series.userOptions.channel} <br>` +
          `<span>Frequency: </span>${this.series.userOptions.freq} MHz <br> ` +
          `<span>Width: </span>${this.series.userOptions.width} MHz <br> ` +
          `<span>Offset: </span>${this.series.userOptions.offset} <br>`
        );
      };
    },
    searchClear() {
      this.searchString = '';
      this.newSearch();
    },
    loadMore() {
      this.offset++;
      this.offsetforRequest = this.offset * this.limit;
      cpeService.getPaginatedCpesForWifiAnalyze(this, this.limit, this.offsetforRequest);
    },

    checkAllState(key) {
      if (this.scanResult && this.scanResult.scanlist && this.scanResult.scanlist[key] && this.ssidsToDisplayV2[key]) {
        return Object.keys(this.scanResult.scanlist[key]).length === this.ssidsToDisplayV2[key].length;
      }
    },


    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
        this.searchLocationId = 'All locations';
      } else {
        this.filters.location = location.id;
        this.searchLocationId = location.id
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.newSearch()


    },
    searchInputHandler() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
      this.delayTimer = setTimeout(() => {
        this.newSearch();
      }, 1000);
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.searchString = '';
    //   this.filters = {
    //     query: '',
    //     interfacesFilter: '',
    //     connectedFilter: '',
    //     location: 'All locations',
    //     wlan: 'All wlans',
    //     with_childs: true
    //   }
    //   this.searchLocationId = 'All locations';
    //   this.sortByFilter = 'Connected'
    //   this.newSearch()
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.searchString = '';
      this.filters = {
        query: '',
        interfacesFilter: '',
        connectedFilter: '',
        // location: 'All locations',
        wlan: 'All wlans',
        // with_childs: true
      }

     // this.searchLocationId = 'All locations';
      if (!this.isSelectedDefaultLocationForRequests){
        this.filters.location = 'All locations';
        this.filters.with_childs = true;
        this.locationInFiltersData = [];
        this.searchLocationId = 'All locations';
      } else {
        this.searchLocationId = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.sortByFilter = 'Connected'
      this.newSearch()
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },

    toggleEdit(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { id } = selectedNode.dataset;
      this.$store.commit('setCpesStatIdForWifiAnalyze', id);
      this.getScanListV2();
    },

    filterItems(items) {
      let filteredCpes = items;
      filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
      // filteredCpes = Filters.locationFilter(filteredCpes, this.filters.location);
      this.filteredCpes = filteredCpes;
      let sortedFilteredCpes = [];
      let first;
      let second;
      if (this.sortMode === 'desc') {
        first = -1;
        second = 1;
      } else if (this.sortMode === 'asc') {
        first = 1;
        second = -1;
      }
      if (this.sortByFilter === 'Disconnected') {
        first = -1;
        second = 1;
        sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
          if (curr.offPeriod > prev.offPeriod) {
            return first;
          }
          if (curr.offPeriod < prev.offPeriod) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Connected') {
        first = 1;
        second = -1;
        sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
          if (curr.offPeriod > prev.offPeriod) {
            return first;
          }
          if (curr.offPeriod < prev.offPeriod) {
            return second;
          }
          return 0;
        });
      }

      return sortedFilteredCpes;
    },
    disableStatMode() {
      this.statCpeId = '';
      this.$store.commit('setCpesStatIdForWifiAnalyze', null);
    },
    newSearch() {
      this.$store.commit('setCpesListForWifiAnalyze', false);
      this.$store.commit('setCpesEditIndex', null);
      this.$store.commit('setCpesStatIdForWifiAnalyze', null);
      this.statCpeId = '';
      this.limit = 30;
      this.offset = 0;
      this.offsetforRequest = 0;
      this.totalResult = false;
      this.canLoadMore = true;
      this.filters.location = this.searchLocationId
      cpeService.getPaginatedCpesForWifiAnalyze(this, this.limit, this.offset);
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    cutName(name) {
      return commonService.cutName(name);
    },
    cpeObj(id) {
      return commonService.cpeObjbyId(id);
    },
    handleDateChangeForRating() {
      this.getCpesSessions(this);
    },
    handleDateChangeForStat(newDate) {
      this.drPickerDateForStat.startDate = newDate.start.valueOf();
      this.drPickerDateForStat.endDate = newDate.end.valueOf();
      statCpeService.getCpeStat(this);
    },
    checkAllBssids(event) {
      const key = event.target.dataset.radioNumber;
      const checkbocState = event.target.checked;
      if (!checkbocState) {
        this.ssidsToDisplayV2[key] = [];
      } else {
        this.ssidsToDisplayV2[key] = [];
        for (const item in this.scanResult.scanlist[key]) {
          this.ssidsToDisplayV2[key].push(item);
        }
      }
      this.responseScannerStatsV2();
    },
    isSsidChecked(key, bssid) {
      return this.ssidsToDisplayV2[key].includes(bssid);
    },
    toggleBssid(key, bssid) {
      if (this.ssidsToDisplayV2[key].indexOf(bssid) !== -1) {
        this.ssidsToDisplayV2[key].splice(this.ssidsToDisplayV2[key].indexOf(bssid), 1);
      } else {
        this.ssidsToDisplayV2[key].push(bssid);
      }
      this.responseScannerStatsV2();
    },
    showScanResult(event) {
      const key = event.target.dataset.radioNumber;
      this.isScanResultShow[key] = !this.isScanResultShow[key];
      this.$forceUpdate();
    },
    responseScannerStatsV2() {
      if (!this.scanResult && !this.scanResult.scanlist) {
        return;
      }
      this.chartSeries = {};
      for (const radioNumber in this.scanResult.scanlist) {
        // this.two_ghz_scanner.series[radioNumber] = [];
        // this.five_ghz_scanner.series[radioNumber] = [];
        this.chartSeries[radioNumber] = {};
        this.chartSeries[radioNumber].two_ghz_scanner = { ...this.two_ghz_scanner };
        this.chartSeries[radioNumber].five_ghz_scanner = { ...this.five_ghz_scanner };
        this.chartSeries[radioNumber].two_ghz_scanner.series = [];
        this.chartSeries[radioNumber].five_ghz_scanner.series = [];
        const scanlist = this.scanResult.scanlist[radioNumber];
        for (const ap in scanlist) {
          const apData = scanlist[ap];
          if (this.ssidsToDisplayV2[radioNumber].includes(apData.bssid)) {
            if (apData.additionData.band === '2.4') {
              const emptyChanlist = [];
              const channelIndexStartChannel = two_ghz_chanlist.indexOf(apData.additionData.startChannel.toString());
              const channelIndex = two_ghz_chanlist.indexOf(apData.channel.toString());
              const channelIndexEndChannel = two_ghz_chanlist.indexOf(apData.additionData.endChannel.toString());
              emptyChanlist.push({
                x: channelIndexStartChannel,
                y: apData.signal,
                marker: { enabled: false, states: { hover: { enabled: false } } }
              });
              emptyChanlist.push({ x: channelIndex, y: apData.signal, marker: { enabled: true, radius: 7 } });
              emptyChanlist.push({
                x: channelIndexEndChannel,
                y: apData.signal,
                marker: { enabled: false, states: { hover: { enabled: false } } }
              });

              this.chartSeries[radioNumber].two_ghz_scanner.series.push({
                name: apData.ssid || apData.bssid,
                data: emptyChanlist,
                channelIndex,
                channel: apData.channel,
                freq: apData.additionData.freq,
                width: apData.additionData.width,
                offset: apData.additionData.offset,
                signal: apData.signal
              });
              // const emptyChanlist = two_ghz_chanlist.map((channel) => null);
              // const channelIndex = two_ghz_chanlist.indexOf(apData.channel.toString());
              // emptyChanlist[channelIndex] = apData.signal;
              // if (channelIndex + 1 < two_ghz_chanlist.length - 1 || channelIndex === 0) {
              //   emptyChanlist[channelIndex + 1] = apData.signal;
              // } else {
              //   emptyChanlist[channelIndex - 1] = apData.signal;
              // }
              // this.chartSeries[radioNumber].two_ghz_scanner.series.push({
              //   name: apData.ssid || apData.bssid,
              //   data: emptyChanlist
              // });
            } else {
              const emptyChanlist = [];
              const channelIndexStartChannel = five_ghz_chanlist.indexOf(apData.additionData.startChannel.toString());
              const channelIndex = five_ghz_chanlist.indexOf(apData.channel.toString());
              const channelIndexEndChannel = five_ghz_chanlist.indexOf(apData.additionData.endChannel.toString());
              emptyChanlist.push({
                x: channelIndexStartChannel,
                y: apData.signal,
                marker: { enabled: false, states: { hover: { enabled: false } } }
              });
              emptyChanlist.push({ x: channelIndex, y: apData.signal, marker: { enabled: true, radius: 7 } });
              emptyChanlist.push({
                x: channelIndexEndChannel,
                y: apData.signal,
                marker: { enabled: false, states: { hover: { enabled: false } } }
              });

              this.chartSeries[radioNumber].five_ghz_scanner.series.push({
                name: apData.ssid || apData.bssid,
                data: emptyChanlist,
                channelIndex,
                channel: apData.channel,
                freq: apData.additionData.freq,
                width: apData.additionData.width,
                offset: apData.additionData.offset,
                signal: apData.signal
              });
              // const emptyChanlist = five_ghz_chanlist.map((channel) => null);
              // const channelIndex = five_ghz_chanlist.indexOf(apData.channel.toString());
              // emptyChanlist[channelIndex] = apData.signal;
              // if (channelIndex + 1 < five_ghz_chanlist.length - 1 || channelIndex === 0) {
              //   emptyChanlist[channelIndex + 1] = apData.signal;
              // } else {
              //   emptyChanlist[channelIndex - 1] = apData.signal;
              // }
              // this.chartSeries[radioNumber].five_ghz_scanner.series.push({
              //   name: apData.ssid || apData.bssid,
              //   data: emptyChanlist
              // });
            }
          }
        }
      }
    },
    getScanListV2() {
      this.$store.commit('toggleLoadingCpesScanner');
      const requests = [];
      const selectedCpeId = this.$store.state.statCpeId;
      const selectedCPE = this.cpesBackgroundScaningEnabledList.filter((cpe) => {
        if (cpe.id === selectedCpeId) {
          return true;
        }
        return false;
      });
      // далее создаем список вайфай интерфейсов выбранной точки,
      // у которых включен режим background scanning
      const selectedCPEInterfaces = {};
      for (const key in selectedCPE[0].config.wifi) {
        if (selectedCPE[0].config.wifi[key].scanningconfig.enabled === true) {
          selectedCPEInterfaces[key] = selectedCPE[0].config.wifi[key];
        }
      }
      this.scanResult = {};
      this.scanResult.scanlist = {};
      this.ssidsToDisplayV2 = {};
      this.isScanResultShow = {};
      for (const key in selectedCPEInterfaces) {
        this.isScanResultShow[key] = false;
        this.scanResult.scanlist[key] = {};
        requests.push(
          Vue.axios
            .post(`${window.RESTAPI || ''}/api/cpes/scan/last`, {
              action: 'R',
              ids: [selectedCpeId],
              q: { radio: `radio${key}` }
            })
            .then(
              (response) => {
                if (response.data.status === 'success') {
                  if (response.data.data.itemslist[0]) {
                    this.scanResult.cpe = JSON.parse(JSON.stringify(response.data.data.itemslist[0].cpe));
                    this.scanResult.timestamp = JSON.parse(JSON.stringify(response.data.data.itemslist[0].timestamp));
                    const band = selectedCPEInterfaces[key].frequency;
                    // eslint-disable-next-line no-unused-vars
                    let chanFreqMap = {};
                    if (band === '2.4') {
                      chanFreqMap = chanFreqMap2;
                    } else {
                      chanFreqMap = chanFreqMap5;
                    }
                    // console.log(chanFreqMap)
                    const scanListfromRequest = JSON.parse(JSON.stringify(response.data.data.itemslist[0].scanlist));
                    const scanList = {};
                    // console.log(scanListfromRequest)
                    for (const itemId in scanListfromRequest) {
                      // console.log(itemId)
                      const item = scanListfromRequest[itemId];
                      item.additionData = {};
                      const freq = chanFreqMap[item.channel];
                      // console.log('chan ' + item.channel)
                      // console.log('freq ' + freq)
                      // eslint-disable-next-line no-unused-vars
                      let width = 20;
                      if (item.htmodelist.HT40 || item.htmodelist.VHT40) {
                        width = 40;
                      }
                      if (item.htmodelist.HT80 || item.htmodelist.VHT80) {
                        width = 80;
                      }
                      // console.log('width ' + width)
                      const offset = item.sec_channel_offset;
                      // console.log('offset ' + offset)
                      let start = 0;
                      let stop = 0;
                      if (offset === 0) {
                        start = freq - width / 2;
                        if (start < 1) {
                          start = 1;
                        }
                        stop = freq + width / 2;
                      }
                      if (offset === 1) {
                        start = freq;
                        stop = freq + width;
                      }
                      if (offset === -1) {
                        start = freq - width;
                        if (start < 1) {
                          start = 1;
                        }
                        stop = freq;
                      }
                      item.additionData.startFreq = start;
                      item.additionData.stopFreq = stop;
                      // console.log('start ' + start)
                      // console.log('stop' + stop)
                      const chanels = [];
                      while (start <= stop) {
                        if (freqChanMap[start]) {
                          chanels.push(freqChanMap[start]);
                        }
                        start += 1;
                      }
                      // console.log(chanels)
                      // console.log('---')
                      item.additionData.offset = offset;
                      item.additionData.signal = item.signal;
                      item.additionData.band = band;
                      item.additionData.freq = freq;
                      item.additionData.channels = chanels;
                      item.additionData.width = width;
                      item.additionData.startChannel = chanels[0];
                      item.additionData.endChannel = chanels[chanels.length - 1];
                      scanList[itemId] = item;
                    }
                    Object.assign(this.scanResult.scanlist[key], JSON.parse(JSON.stringify(scanList)));
                    // console.log(this.scanResult.scanlist)
                    this.ssidsToDisplayV2[key] = {};
                    this.ssidsToDisplayV2[key] = Object.keys(this.scanResult.scanlist[key]);
                  }
                } else if (response.data.status === 'error') {
                  VueNotifications.error({ message: response.data.description });
                }
              },
              (err) => {
                VueNotifications.error({ message: err });
              }
            )
        );
      }
      // ждем результатов по всем интерфесам выбранной точки
      Promise.all(requests)
        .then(() => {
          this.responseScannerStatsV2();
          this.$store.commit('toggleLoadingCpesScanner');
        })
        .catch((err) => {
          this.$store.commit('toggleLoadingCpesScanner');
          VueNotifications.error({ message: err });
        });
    },
    wlanObj(id) {
      return commonService.wlanObjbyId(id);
    }
  },
  watch: {},
  // created() {
  //   this.two_ghz_scanner.tooltip.formatter = this.getTooltipWithi18n();
  //   this.five_ghz_scanner.tooltip.formatter = this.getTooltipWithi18n();
  //
  //   this.$store.commit('setCpesListForWifiAnalyze', false);
  //   cpeService.getPaginatedCpesForWifiAnalyze(this, this.limit, this.offset);
  //   // Get wlans for multiselect if empty in store
  //   this.$store.commit('setCpesEditIndex', null);
  //   this.$store.commit('setCpesEditIndex', null);
  //   this.$store.commit('setCpesStatIdForWifiAnalyze', null);
  // },

  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation;
         locationForFiltering = 'All locations'
      }


      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
      this.searchLocationId = locationForFiltering;
    }

    this.two_ghz_scanner.tooltip.formatter = this.getTooltipWithi18n();
    this.five_ghz_scanner.tooltip.formatter = this.getTooltipWithi18n();

    this.$store.commit('setCpesListForWifiAnalyze', false);
    cpeService.getPaginatedCpesForWifiAnalyze(this, this.limit, this.offset);
    // Get wlans for multiselect if empty in store
    this.$store.commit('setCpesEditIndex', null);
    this.$store.commit('setCpesEditIndex', null);
    this.$store.commit('setCpesStatIdForWifiAnalyze', null);
  },

  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },
    momentnow(value) {
      if (value) {
        return moment.duration(value, 'seconds').format('D [day] h [h], mm [min], ss [sec]');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>

<style lang="scss">
                .input-group-for-location-select-in-monitoringWiFiAnalyzer--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-monitoringWiFiAnalyzer--filters .select__list-container {
    width: 400px;
  }

.cpes {
  display: flex;
}

.cpes-rating-list {
  width: 100%;
}

.cpes-stat {
  display: inline-block;
  width: 0;
  word-wrap: break-word;
}

.stat-visible .cpes-rating-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 25%;
}

.stat-visible .cpes-stat {
  transition: width 0.2s;
  width: 75%;
}

.cpe-row {
  transition: background 0.15s;
  cursor: pointer;
}

.cpe-row.selected {
  background: #d9e3ec;
}

.cpe-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.cpe-row--sorted {
  background: rgba(236, 236, 236, 0.59);
}

.btn {
  cursor: pointer;
}

.stat-visible .progress {
  max-width: 130px;
}

.badge-status__breakdown {
  width: 5px !important;
  height: 5px !important;
  border-radius: 50% !important;
  margin-right: 0 !important;
  padding: 0;
}

.sessions {
  display: flex;
  justify-content: space-between;

  margin-bottom: 0.5rem;
}

div[data-popover='webaccess'] {
  background: #444;
  color: #f9f9f9;
  font-size: 12px;
  line-height: 1.5;
  margin: 3px;
  text-align: center;
  padding: 7px;
  height: 30px;
  width: 100%;
}

div[data-popover='analyzer'] {
  background: #444;
  color: #f9f9f9;
  font-size: 12px;
  line-height: 1.5;
  margin: 3px;
  text-align: center;
  padding: 7px;
  height: 30px;
  width: 100%;
}

.loader-backdrop-monitor-wifianalyzer:after {
  position: absolute;
  top: 30px;
}
</style>
<style lang="scss" scoped>
.custom-margin-left {
  margin-left: 1em;
}
</style>
